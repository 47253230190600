import Link from "../components/Link/Link"

const Terms = () => {
  return (
<div className="App page-width px-6">
  <div className="mt-4 mb-12">
    <Link url="/" text="Back to home" leftArrow={true} />
  </div>
  <h1 className="font-bold text-3xl mb-8">Terms and Conditions</h1>

<h2 className="font-bold text-2xl my-4">1. Introduction</h2>
<p>Welcome to Typesmith ("Service"), operated by Venture Stream Ltd, a company registered in England and Wales with registration number 08054842 ("we", "us", "our"). These Terms and Conditions ("Terms") govern your access to and use of our services, including any related software, platforms, and technologies provided by Typesmith, and all content, functionality, and services offered through the Service.</p>

<h2 className="font-bold text-2xl my-4">2. Acceptance of Terms</h2>
<p>By accessing or using the Service, you confirm that you accept these Terms and that you agree to comply with them. If you do not agree to these Terms, you must not use our Service. We recommend that you print or save a copy of these Terms for future reference.</p>

<h2 className="font-bold text-2xl my-4">3. Service Description</h2>
<p>Typesmith offers AI-powered copywriting services designed to optimise e-commerce content, including but not limited to product descriptions, meta titles, meta descriptions, and advertising copy. Our Service utilises advanced AI technology, including large language models (LLMs), to generate content based on input data provided by you. Typesmith employs artificial intelligence and machine learning technologies to enhance productivity and quality; however, all AI-generated content should be reviewed for accuracy, appropriateness, and suitability for your intended use.</p>

<h2 className="font-bold text-2xl my-4">4. API Service Usage</h2>
<p><strong>Scope of Use:</strong> Typesmith integrates with third-party APIs provided by OpenAI and/or Anthropic for content generation. Users are permitted to interact with these services solely through the Service interface, subject to certain limitations. Users may not use the APIs to generate harmful, illegal, or copyrighted content or for any other prohibited activities outlined by OpenAI or Anthropic.</p>
<p><strong>Third-Party API Compliance:</strong> Users must comply with OpenAI and Anthropic’s respective terms of use, as Typesmith partially relies on these external APIs. Any violations of these terms by users may lead to restrictions on access to the Service.</p>

<h2 className="font-bold text-2xl my-4">5. User Obligations and Conduct</h2>
<p>You agree to use Typesmith only for lawful purposes and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of the Service by any third party.</p>
<p><strong>Content Accuracy:</strong> You are responsible for ensuring that any data, including product titles, attributes, or images you submit, is accurate and does not contain any material that is copyrighted, illegal, offensive, or otherwise objectionable.</p>
<p><strong>Compliance with Applicable Laws:</strong> You agree to comply with all applicable laws, including data protection laws and intellectual property laws, in your use of the Service.</p>
<p><strong>Prohibited Uses:</strong> You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service without express written permission from us. You must not use the Service in any manner that could damage, disable, overburden, or impair the Service or interfere with any other party's use of the Service.</p>

<h2 className="font-bold text-2xl my-4">6. Intellectual Property Rights</h2>
<p>The Service and all related content, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio) are owned by Venture Stream Ltd, its licensors, or other providers of such material and are protected by international copyright, patent, trademark, and other intellectual property or proprietary rights laws.</p>
<p><strong>Ownership and Licensing:</strong> OpenAI and Anthropic retain intellectual property rights over their respective models, while Typesmith retains rights over the platform interface and unique functionalities. Any modifications to or reverse-engineering of these models or the software underlying the Service are prohibited.</p>
<p><strong>User Ownership of AI-Generated Content:</strong> Since you purchase credits to generate specific content through Typesmith, all content created using those credits is fully owned by you upon generation. You have the right to use, edit, publish, and distribute the generated content as needed for your e-commerce purposes. Typesmith does not retain any ownership or license over content generated using your purchased credits.</p>
<p><strong>Content Ownership:</strong> Although generated outputs are based on user inputs, these outputs may not be considered original intellectual property. Verification may be required to prevent potential IP infringement or misuse.</p>
<p><strong>Third-Party AI Processing:</strong> Typesmith relies on third-party large language models (LLMs) to process and generate content based on the data you provide. While we strive to ensure the accuracy and reliability of the generated content, we cannot guarantee the performance or outcomes of these third-party AI systems. By using the Service, you acknowledge and accept that content generation is dependent on these external technologies.</p>

<h2 className="font-bold text-2xl my-4">7. Data Processing and Retention</h2>
<p><strong>User Content and Input Processing:</strong> Typesmith processes user input data through OpenAI and/or Anthropic APIs to generate outputs. These providers may not store input data post-processing, but Typesmith may retain user data to enhance Service functionality or for compliance purposes, as outlined in our Privacy Policy.</p>
<p><strong>AI-Generated Output Liability:</strong> Due to the nature of generative AI, Typesmith cannot guarantee the accuracy or appropriateness of generated content and is not liable for any content produced by these APIs. Users should review and verify generated content for their specific purposes.</p>

<h2 className="font-bold text-2xl my-4">8. Service Availability and Limitations</h2>
<p><strong>API Service Dependency:</strong> Typesmith’s functionality is dependent on the availability of OpenAI and/or Anthropic’s APIs. Any disruptions or changes in these third-party services may impact the performance or availability of the Service.</p>
<p><strong>Right to Modify:</strong> We reserve the right to modify or discontinue the use of OpenAI/Anthropic services and may notify users of material changes that could impact their experience with the Service.</p>

<h2 className="font-bold text-2xl my-4">9. Limitation of Liability and Indemnification</h2>
<p><strong>Limitation of Liability for AI Outputs:</strong> Typesmith limits liability for any damages arising from the use of AI-generated outputs. Users are advised to independently verify the accuracy of outputs, especially for regulated or sensitive uses.</p>
<p><strong>Indemnification:</strong> You agree to indemnify and hold harmless Venture Stream Ltd and its affiliates from any liabilities, losses, damages, claims, and expenses arising from misuse of generated content or violations of third-party rights due to user interactions with AI-generated outputs.</p>

<h2 className="font-bold text-2xl my-4">10. Disclaimer of Warranties and Limitation of Liability</h2>
<p>The Service is provided on an "as is" and "as available" basis without any warranties of any kind, either express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
<p><strong>No Liability for Indirect Damages:</strong> In no event will Venture Stream Ltd, its directors, employees, partners, agents, suppliers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorised access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage.</p>
<p><strong>Liability Cap:</strong> To the maximum extent permitted by applicable law, Venture Stream Ltd's total liability to you for any claims arising out of or relating to these Terms or your use of the Service is limited to the amount paid by you to us, if any, in the twelve (12) months preceding the claim.</p>

<h2 className="font-bold text-2xl my-4">11. Modifications to the Terms and the Service</h2>
<p>We reserve the right to modify these Terms at any time. We will provide notice of any changes by posting the new Terms on the Service. Your continued use of the Service after any such change constitutes your acceptance of the new Terms. If you do not agree with the modified Terms, you must stop using the Service.</p>
<p><strong>Updates to AI Models and Features:</strong> Typesmith may update, enhance, or modify the AI models, features, and functionalities offered within the Service. We may also discontinue or limit certain features without notice, and we do not guarantee the continued availability of any specific functionality or feature.</p>

<h2 className="font-bold text-2xl my-4">12. Data Privacy and Security</h2>
<p>Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and protect your personal data. You agree that by using the Service, you consent to the collection, use, and sharing of such data as described in our Privacy Policy.</p>
<p><strong>Data for AI Model Improvement:</strong> We may use anonymised and aggregated data from your interactions with the Service to improve our AI models, enhance Service functionality, and support research and development. No personally identifiable information will be included in this aggregated data.</p>

<h2 className="font-bold text-2xl my-4">13. Governing Law and Jurisdiction</h2>
<p>These Terms shall be governed and construed in accordance with the laws of England and Wales, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. You agree that any dispute arising from or relating to these Terms shall be subject to the exclusive jurisdiction of the courts of England and Wales.</p>

<h2 className="font-bold text-2xl my-4">14. Contact Us</h2>
<p>If you have any questions about these Terms, please contact us at <a href="mailto:hi@venturestream.co.uk">hi@venturestream.co.uk</a>.</p>

</div>

  )
}

export default Terms