const HowItWorks = () => {
  return (
    <div id="howitworks" className="section-grid my-8 lg:my-10 px-6 lg:px-0">
      <div className="lg:col-start-2 lg:col-span-11">
        <div className="bg-lightgrey rounded-2xl p-8 lg:p-24 lg:pr-0 grid grid-cols-1 lg:grid-cols-2 mr-0 lg:mr-[150px] gap-8">
          <div>
            <h2 className="font-medium text-4xl lg:text-6xl mb-8">How it works</h2> 
            <p className="text-xl md:text-2xl text-offblack my-8">Typesmith uses cutting-edge AI technology to craft unique, SEO-tailored product descriptions for ecommerce stores in minutes.</p>
            <p className="my-4 text-[#777777]">Our custom AI analyses your existing data such as product titles, descriptions, collections and attributes. It then takes your brand’s tone of voice, target audience and writing style into consideration and crafts a series of import-ready titles, meta titles, product descriptions, on-page descriptions that are laser-focused on your brand and written to persuade.</p>
            <p className="my-4 text-[#777777]">You can also guide the model with custom instructions. We'll save your tone of voice and brand data for re-use, so you can quickly come back and process more descriptions when needed.</p>
            <p className="my-4 text-[#777777]">We automatically recognise data from some popular commerce systems like Shopify, Magento, WooCommerce and Visualsoft. But, don't worry - we let you map your own CSV fields whatever platform you're using.</p>
          </div>
          <div className="relative flex items-center">
            <img src="../../assets/seo.svg" alt="SEO Graphic" loading="lazy" className="ml-0 lg:ml-[10%] xl:ml-[17%]" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks;