import Question from "../Question/Question";

const FAQ = () => {

  const questions = [
    {
      question: "What platforms does Typesmith integrate with?",
      answer: "Typesmith integrates with popular e-commerce platforms such as Shopify, Magento, Visualsoft, WooCommerce, and supports CSV file uploads, making it easy to generate content for a wide range of online stores."
    },
    {
      question: "Can Typesmith help with SEO optimisation?",
      answer: "Yes, Typesmith is designed to generate SEO-optimised product descriptions, titles, and meta descriptions, ensuring that your products are more visible on search engines and can attract a larger audience."
    },
    {
      question: "How does the credit system work?",
      answer: "With Typesmith, you purchase credits up front, which are then used to generate product content. Each credit corresponds to one generation of product data, giving you flexibility in managing your budget and content needs."
    },
    {
      question: "Is Typesmith suitable for dropshippers and wholesalers?",
      answer: "Yes, Typesmith is particularly useful for dropshippers, wholesalers, and other high-volume sellers who need to manage and update large product catalogues efficiently. The bulk generation feature saves time and ensures consistency across all product listings."
    },
    {
      question: "Can I customise the tone of the product descriptions?",
      answer: "Absolutely. Typesmith allows you to set specific guidelines for tone and style, so the content generated aligns with your brand voice, whether it’s formal, friendly, or creative."
    },
    {
      question: "Does Typesmith require a lot of technical knowledge to use?",
      answer: "No, Typesmith is designed to be user-friendly and intuitive. Simply upload your product data, specify your preferences, and let Typesmith handle the rest. No technical expertise is needed."
    },
    {
      question: "How accurate is the product data generated by Typesmith?",
      answer: "Typesmith uses advanced AI models to ensure that the product descriptions and other data are relevant, accurate, and high quality. However, we recommend a final review to ensure all generated content meets your brand’s standards."
    },
    {
      question: "Does Typesmith store my product data?",
      answer: "Typesmith processes your data to generate content, and we store the CSV file for you to download, but we don't store product data after processing, or use it to train models, ensuring that your information remains secure and private."
    }
  ];
  

  return (
    <div className="px-6 border-t border-lightgrey my-12 lg:my-10 pt-12 lg:pt-20">
      <div className="max-w-[888px] mx-auto">
        <h2 className="font-medium leading-none	text-2xl lg:text-[48px] mb-8 lg:mb-10">Common Questions</h2>
        <div className="grid gap-8">
          {questions.map((question, index) => {
            return (
              <Question key={index} question={question.question} answer={question.answer} />
            )
          })}
          </div>
      </div>
    </div>

  );
}

export default FAQ;