const Overview = () => {
  var sectionClasses = "border-t border-lightgrey mb-8 lg:mb-0";
  var titleClasses = "py-8 lg:py-16 text-[22px] md:text-[38px] font-medium";
  var paraClasses = "mb-4";
  
  return (
    <div id="splitSection" className="relative section-grid my-8 lg:my-10 px-6 lg:px-0">
      <div className={sectionClasses + " lg:col-start-2 lg:col-span-5"}>
        <h3 className={titleClasses}>Crafted to Convert</h3>
        <div>
          <p className={paraClasses}>Crafting product descriptions that are optimised for search engines can significantly boost your website’s visibility and drive more traffic. .</p>
          <p className={paraClasses}>But when you have hundreds (or even thousands) of products in your catalogue, writing unique, high-quality descriptions for every single one is time consuming - even for experienced copywriters. Our team can save you serious time by combining our SEO expertise with the power of AI to produce high-quality, fully optimised product descriptions that are crafted to convert.</p>
        </div>
      </div>
      <div className={sectionClasses + " lg:col-start-7 lg:col-span-5"}>
        <h3 className={titleClasses}>Engineered for Efficiency</h3>
        <p className={paraClasses}>For dropshippers, wholesalers, and other high-volume e-commerce businesses, managing large amounts of product data can be a major challenge. Typesmith streamlines this process by generating bulk, SEO-optimised content through CSV imports from popular platforms like Shopify, Magento, Visualsoft, and WooCommerce.</p>
        <p className={paraClasses}>Typesmith can quickly generate consistent, high-quality product descriptions, meta titles, and other essential product details across your entire catalogue in minutes. This powerful tool saves time and ensures accuracy, making it ideal for businesses that need to frequently update or scale their product offerings without getting bogged down by manual content creation.</p>
      </div>
    </div>
  )
}

export default Overview