import { TerminalEmulator } from "../../helpers/terminal-emulator"
import { useEffect } from "react";
import CardGraphic from "../CardGraphic/CardGraphic";

const Overview = () => {
  let executed = false;

  const runEmulator = () => {
    if (!executed) {
      executed = true;
      var TE = TerminalEmulator.init(document.getElementById('screen'));
  
      TE.wait(1000, false)
        .then( TE.enterInput.bind(TE, 'Get product title') )
        .then( TE.enterCommand.bind( TE ) )
        .then( TE.enterInput.bind(TE, '“Men’s Star Graphic Tee”') )
        .then( TE.enterCommand.bind( TE ) )
        .then( TE.enterInput.bind(TE, 'Extracting data, brand, writing style, benefits') )
        .then( TE.enterCommand.bind( TE ) )
        .then( TE.enterInput.bind(TE, 'Generating new content_') )
    };
  }

  useEffect(() => {
    const onScroll = () => {
      let overview = document.getElementById('overview');
      let rect = overview.getBoundingClientRect();

      if(rect.y < 750) {
        overview.classList.add('animate-section');
        runEmulator();
        window.removeEventListener('scroll', onScroll);
      }
    };
    window.addEventListener('scroll', onScroll);
    // eslint-disable-next-line
  }, []);
  
  return (
    <div id="overview" className="relative section-grid my-8 lg:my-10 px-6 lg:px-0">
      <div className="relative lg:col-start-2 lg:col-span-10 flex items-center lg:justify-end">
        <p className="lg:max-w-[40%] lg:text-lg">Typesmith is an AI-powered tool designed to streamline e-commerce content creation. It generates high-quality product descriptions and other product data through CSV uploads from popular platforms like Shopify, Magento, Visualsoft, WooCommerce, and more, ensuring your content is SEO-optimised and ready for your online store.</p>
      </div>
      <div className="relative lg:col-start-2 lg:col-span-6">
        <div className=" bg-lightgrey rounded-2xl mt-16 lg:my-0 pb-0 lg:pb-8">
          <CardGraphic />
        </div>
        <img src="../../assets/cart.svg" alt="Cart Graphic" loading="lazy" className="hidden lg:block relative float-right -mt-20" />
      </div>
      <div className="lg:col-start-7 lg:col-span-5 bg-black rounded-2xl p-6 lg:p-16 relative lg:absolute lg:top-60 z-2">
        <h4 className="text-lg lg:text-xl font-medium text-yellow">Fast & Smart</h4>
        <div className="relative h-[150px] my-8">
          <div id="screen" className="terminal_emulator"></div>
        </div>
        <p className="text-[#BEBEBE] text-base lg:text-lg leading-normal">Typesmith can process thousands of product descriptions within minutes, each with useful and relevant information, guided by you and processed by AI.</p>
      </div>
    </div>
  )
}

export default Overview