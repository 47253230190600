import Link from "../Link/Link";

const Pricing = () => {
  const pricing_model = [
    {
      title: "Free Trial",
      price: "FREE",
      description: "5 Trial Products",
      features: [
        "Generate 5 products per CSV",
        "Optimised Product Titles",
        "HTML-formatted Descriptions",
        "Optimised, site-specific Meta Descriptions",
        "Optimised, site-specific Meta Titles"
      ],
      note: "Limited access, try it today!"
    },
    {
      title: "100 Credits",
      price: "£5",
      description: "100 Products",
      features: [
        "Optimised Product Titles",
        "HTML-formatted Descriptions",
        "Optimised, site-specific Meta Descriptions",
        "Optimised, site-specific Meta Titles",
        "Choose between 16 different languages"
      ]
    },
    {
      title: "500 Credits",
      price: "£20",
      description: "500 Products",
      features: [
        "A Saving of 20%",
        "Optimised Product Titles",
        "HTML-formatted Descriptions",
        "Optimised, site-specific Meta Descriptions",
        "Optimised, site-specific Meta Titles",
        "Choose between 16 different languages"
      ]
    },
    {
      title: "1000 Credits",
      price: "£30",
      description: "1000 Products",
      features: [
        "A Saving of 40%",
        "Product Titles",
        "Optimised HTML-formatted Descriptions",
        "Optimised, site-specific Meta Descriptions",
        "Optimised, site-specific Meta Titles",
        "Choose between 16 different languages"
      ]
    }
  ]

  return (
    <div id="pricing">
      <div className="section-grid page-width mt-12 lg:mt-20 px-6">
        <div className="lg:col-start-2 lg:col-span-11">
          <h2 className="text-xl lg:text-3xl text-[#858585] mb-4 font-medium">Pricing</h2>
        </div>
        <div className="lg:col-start-2 lg:col-span-10">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
            {pricing_model.map((model, index) => (
              <div key={index} className="flex">
                <div className="bg-white rounded-md shadow-md">
                  <div className="bg-lightgrey p-6 lg:px-12 lg:py-8 border-t-[10px] border-yellow rounded-t-sm">
                    <h3 className="text-base lg:text-lg font-medium mb-2 uppercase text-black">Typesmith {model.title}</h3>
                    <p className="text-base lg:text-xl mb-8 text-offblack">{model.description}</p>
                    <h4 className="text-2xl lg:text-3xl font-bold">{model.price}</h4>
                  </div>
                  <div className="p-6 lg:p-12">
                    <p>What you get</p>
                    <ul>
                      {model.features.map((feature, index) => (
                        <li key={index} className="mt-6">{feature}</li>
                      ))}
                    </ul>
                    {model.note && (
                      <p className="mt-6 text-sm text-gray-500">{model.note}</p>
                    )}
                  </div>
                  {model.plus && (
                    <div className="p-6 lg:p-12 border-t border-[#E1E3E5]">
                      <p className="mb-8">Plus</p>
                      <ul>
                        {model.plus.map((feature, index) => (
                          <li key={index} className="mt-6">{feature}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing;