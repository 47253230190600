import Link from "../components/Link/Link"

const Cookie = () => {
  return (
    <div className="App page-width px-6">
      <div className="mt-4 mb-12">
        <Link url="/" text="Back to home" leftArrow={true} />
      </div>
      <h1 className="font-bold text-3xl mb-8">Cookie Policy</h1>
      
      <h2 className="font-bold text-2xl my-4">1. Introduction</h2>
      <p>This Cookie Policy explains how Typesmith, operated by Venture Stream Ltd ("we", "us", "our"), uses cookies and similar technologies to recognize you when you visit our website at www.typesmith.ai. It explains what these technologies are, why we use them, and your rights to control our use of them. This policy should be read alongside our <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms-and-conditions">Terms and Conditions</a>.</p>
      
      <h2 className="font-bold text-2xl my-4">2. What are cookies?</h2>
      <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
      
      <h2 className="font-bold text-2xl my-4">3. Why do we use cookies?</h2>
      <p>We use first-party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies enable us to track and target the interests of our users to enhance the experience on our website. Third parties also serve cookies through our website for purposes such as analytics and performance monitoring.</p>
      
      <h2 className="font-bold text-2xl my-4">4. Types of Cookies We Use</h2>
      <p><strong>Essential Cookies:</strong> These cookies are strictly necessary to provide you with services available through our website, such as secure access to certain areas.</p>
      <p><strong>Analytics and Performance Cookies:</strong> These cookies collect information about traffic to our website and how users navigate our site. The information gathered does not identify any individual visitor, allowing us to improve site functionality and user experience.</p>
      <p><strong>Functionality Cookies:</strong> These cookies allow our website to remember choices you make, providing enhanced, more personalized features.</p>
      
      <h2 className="font-bold text-2xl my-4">5. How Can You Control Cookies?</h2>
      <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by using our Cookie Consent Manager, which allows you to select specific categories of cookies. Note that essential cookies cannot be rejected as they are required for the operation of our website.</p>
      <p>Most browsers also allow you to control cookies through the browser settings. As each browser has its own method for managing cookies, consult your browser’s help documentation for instructions.</p>
      
      <h2 className="font-bold text-2xl my-4">6. Changes to This Cookie Policy</h2>
      <p>We may update this Cookie Policy periodically to reflect changes in our cookie practices or for other operational, legal, or regulatory reasons. Please revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
      
      <h2 className="font-bold text-2xl my-4">7. Contact Us</h2>
      <p>If you have any questions about our use of cookies or other tracking technologies, please contact us at <a href="mailto:hi@venturestream.co.uk">hi@venturestream.co.uk</a>.</p>
      
      <p className="mt-6">This Cookie Policy is aligned with our <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms-and-conditions">Terms and Conditions</a>, which provide further details on our data handling practices. Please refer to these documents for a comprehensive understanding of how Typesmith manages user information and privacy.</p>
    </div>
  );
};

export default Cookie