import Link from "../components/Link/Link"

const Privacy = () => {
  return (
<div className="App page-width px-6">
  <div className="mt-4 mb-12">
    <Link url="/" text="Back to home" leftArrow={true} />
  </div>
  <h1 className="font-bold text-3xl mb-8">Privacy Policy</h1>

<h2 className="font-bold text-2xl my-4">1. Introduction</h2>
<p>This Privacy Policy explains how Typesmith ("we", "us", "our") collects, uses, and shares your personal information when you use our Service, which is operated by Venture Stream Ltd, a company registered in England and Wales.</p>

<h2 className="font-bold text-2xl my-4">2. Information We Collect</h2>
<p>We collect and process information you provide directly to us when you use our Service. This includes product details, images, and any other data necessary for generating content, especially for e-commerce platforms such as Shopify, Magento, Visualsoft, WooCommerce, and CSV uploads.</p>
<p>Typesmith also collects information about your interactions with our Service to help improve performance and usability. Additionally, we utilise third-party services, such as OpenAI and Anthropic, for content generation purposes. Data processed through these APIs includes user-provided text inputs but does not retain user data beyond the immediate content generation process.</p>

<h2 className="font-bold text-2xl my-4">3. Use of Your Information</h2>
<p>We use your information to provide, maintain, and improve our Service in the following ways:</p>
<ul>
    <li>To generate and manage your content, ensuring it aligns with your preferences and optimises your e-commerce product listings.</li>
    <li>To communicate with you, including responding to inquiries and providing customer support.</li>
    <li>To conduct research and development, which allows us to enhance our Service and improve AI-generated content quality.</li>
    <li>To monitor usage, detect, prevent, and address technical issues, and improve overall Service performance.</li>
    <li>To use anonymised and aggregated data for AI model improvement, ensuring no personally identifiable information is included in this aggregated data.</li>
</ul>

<h2 className="font-bold text-2xl my-4">4. Sharing Your Information</h2>
<p>We do not sell your personal information to third parties. However, we may share your information with:</p>
<ul>
    <li>Third-party services, such as OpenAI and Anthropic, to facilitate content generation based on the data you provide. These third parties act as data processors under UK GDPR for processing user-provided text inputs solely for content generation purposes, with no data retention beyond processing.</li>
    <li>Law enforcement, regulators, or other third parties as required to comply with legal obligations or protect the rights, property, or safety of Typesmith, our users, or others.</li>
    <li>Other parties with your explicit consent, if necessary for providing or improving the Service.</li>
</ul>

<h2 className="font-bold text-2xl my-4">5. Data Security</h2>
<p>We implement industry-standard measures to protect your personal information from accidental loss, unauthorised access, use, alteration, and disclosure. This includes encryption in transit for data sent to third-party APIs like OpenAI and Anthropic and access controls to prevent unauthorised processing. While we take these precautions, please be aware that no security measure is completely infallible, and we encourage you to take appropriate steps to safeguard your own data.</p>

<h2 className="font-bold text-2xl my-4">6. Third-Party AI Processing</h2>
<p>Our Service relies on third-party AI models, such as OpenAI's and Anthropic’s large language models (LLMs), to process and generate content based on the information you provide. These APIs serve as data processors, processing input data solely for generating outputs. No data is stored by OpenAI or Anthropic post-processing. While we strive to ensure reliable and high-quality content, we cannot guarantee the performance or outcomes of these external technologies. By using our Service, you acknowledge that content generation is dependent on these external models.</p>

<h2 className="font-bold text-2xl my-4">7. User Rights and Data Transparency</h2>
<p><strong>Transparency and User Rights:</strong> You have rights over your personal data under applicable data protection laws, including the rights of access, correction, deletion, and portability. We aim to be transparent with our data processing practices and ensure that users are informed about how their data is used.</p>
<p><strong>Data Minimisation:</strong> We limit the collection of data to only what is necessary for providing the Service. We also ensure that no data is stored by third-party AI providers like OpenAI and Anthropic beyond the necessary processing steps, unless explicitly retained by Typesmith for Service functionality or compliance purposes.</p>

<h2 className="font-bold text-2xl my-4">8. International Data Transfers and Compliance</h2>
<p>Data processed by OpenAI and Anthropic may involve international data transfers outside of the UK/EU, particularly to the United States. We have implemented safeguards, such as Standard Contractual Clauses (SCCs), to ensure these transfers comply with UK GDPR requirements and provide an adequate level of protection for personal data.</p>
<p><strong>Compliance Statement:</strong> Typesmith is committed to compliance with all applicable data protection laws, including the UK GDPR, especially regarding data processed through integrated AI technologies. We continually monitor regulatory changes to ensure ongoing compliance.</p>

<h2 className="font-bold text-2xl my-4">9. Changes to This Privacy Policy</h2>
<p>We may update our Privacy Policy periodically to reflect changes in our practices, the third-party providers we use, or new legal requirements. If there are significant changes, we will notify you by updating this page and, where applicable, through other communication methods to ensure transparency and compliance.</p>

<h2 className="font-bold text-2xl my-4">10. Contact Us</h2>
<p>If you have any questions about this Privacy Policy or our privacy practices, please contact us at <a href="mailto:hi@venturestream.co.uk">hi@venturestream.co.uk</a>.</p>

</div>

  )
}

export default Privacy